/*
 * Yafeapedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.57
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ArticlesMediaDTO from './ArticlesMediaDTO';
import AuthorDTO from './AuthorDTO';
import CategoryDTO from './CategoryDTO';

/**
 * The ArticleDTO model module.
 * @module model/ArticleDTO
 * @version 1.0.1
 */
export default class ArticleDTO {
  /**
   * Constructs a new <code>ArticleDTO</code>.
   * @alias module:model/ArticleDTO
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleDTO</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleDTO} obj Optional instance to populate.
   * @return {module:model/ArticleDTO} The populated <code>ArticleDTO</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleDTO();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('mediaEntities'))
        obj.mediaEntities = ApiClient.convertToType(data['mediaEntities'], [ArticlesMediaDTO]);
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('category'))
        obj.category = CategoryDTO.constructFromObject(data['category']);
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('imgUrl'))
        obj.imgUrl = ApiClient.convertToType(data['imgUrl'], 'String');
      if (data.hasOwnProperty('lang'))
        obj.lang = ApiClient.convertToType(data['lang'], 'Number');
      if (data.hasOwnProperty('addedAt'))
        obj.addedAt = ApiClient.convertToType(data['addedAt'], 'Date');
      if (data.hasOwnProperty('lastEdit'))
        obj.lastEdit = ApiClient.convertToType(data['lastEdit'], 'Date');
      if (data.hasOwnProperty('author'))
        obj.author = AuthorDTO.constructFromObject(data['author']);
      if (data.hasOwnProperty('lastEditBy'))
        obj.lastEditBy = AuthorDTO.constructFromObject(data['lastEditBy']);
      if (data.hasOwnProperty('deletedAt'))
        obj.deletedAt = ApiClient.convertToType(data['deletedAt'], 'Date'); 
      if (data.hasOwnProperty('keywords'))
        obj.keywords = ApiClient.convertToType(data['keywords'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ArticleDTO.prototype.id = undefined;

/**
 * @member {Array.<module:model/ArticlesMediaDTO>} mediaEntities
 */
ArticleDTO.prototype.mediaEntities = undefined;

/**
 * @member {String} content
 */
ArticleDTO.prototype.content = undefined;

/**
 * @member {module:model/CategoryDTO} category
 */
ArticleDTO.prototype.category = undefined;

/**
 * @member {String} title
 */
ArticleDTO.prototype.title = undefined;

/**
 * @member {String} imgUrl
 */
ArticleDTO.prototype.imgUrl = undefined;

/**
 * @member {Number} lang
 */
ArticleDTO.prototype.lang = undefined;

/**
 * @member {Date} addedAt
 */
ArticleDTO.prototype.addedAt = undefined;

/**
 * @member {Date} lastEdit
 */
ArticleDTO.prototype.lastEdit = undefined;

/**
 * @member {module:model/AuthorDTO} author
 */
ArticleDTO.prototype.author = undefined;

/**
 * @member {module:model/AuthorDTO} lastEditBy
 */
ArticleDTO.prototype.lastEditBy = undefined;

/**
 * @member {Date} deletedAt
 */
ArticleDTO.prototype.deletedAt = undefined;

/**
 * @member {String} keywords
 */
ArticleDTO.prototype.keywords = undefined;
  
