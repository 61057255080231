<script setup>
import { useRouter } from "vue-router";
import { formatDate } from "@/utils/utils.js";
import ViewCounter from './ViewCounter.vue';

const { article, media, mediaType } = defineProps(["article", "media", "mediaType"]);
</script>

<template>
  <div class="item">
    <div class="item">
      <div class="post-slide">
        <div class="post-img">
          <img
          :src="article? article.imgUrl : media.url"  alt=""
          />
        </div>
        <div class="post-content">
          <h3 class="post-title txt-center">
            <router-link class="" :to=" article? `/article/${article.id}` : `/media/${media.id}/${mediaType}`">
              {{ article? article.title : media.title }}
            </router-link>
          </h3>
          <p class="post-description quill-editor q-label" dir="" v-html="article? article.content : media.description" />
          <div class="all-btn">
            <router-link :to="article? `/article/${article.id}` : `/media/${media.id}/${mediaType}`" class="big-btn mid-btn mb-3">
              اقرأ المزيد
            </router-link>
          </div>
          <div class="text-right">
            <i
              class="fa-regular fa-circle-user txt-center"
              style="color: #d06106; margin: 0 3px"
            ></i>
            <span class="post-date"> {{article? article.author.displayName : media.author.displayName}} </span>
            <span> </span>
          </div>

          <div class="text-right">
            <i
              class="fa-regular fa-clock"
              style="color: #d06106; margin: 0 3px"
            ></i>
            <span class="post-date">{{ article? formatDate(article.addedAt) : formatDate(media.addedAt) }}</span>
            <span> </span>
          </div>

          <div class="text-right" v-if="article">
            <ViewCounter :articleId="article.id" iconColor="#d06106" textColor="var(--main-color)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.post-slide {
  background: var(--section-background);
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.post-slide:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.post-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
}

.post-slide .post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
  transform: scale(1.1, 1.1);
}

.post-slide .post-content {
  background: var(--section-background);
  padding: 20px 25px;
  border-radius: 0 0 15px 15px;
}

.post-slide .post-title a {
  font-size: 18px;
  font-weight: 600;
  color: var(--scond-color);
  transition: all 0.3s ease;
  display: block;
  margin: 10px 0;
  line-height: 1.4;
}

.post-slide .post-title a:hover {
  text-decoration: none;
  color: var(--scond-color);
}

.post-slide .post-description {
  line-height: 1.6;
  color: var(--main-color);
  margin: 15px 0;
  font-size: 0.95rem;
}

.mid-btn {
  padding: 8px 20px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.text-right {
  display: flex;
  align-items: center;
  margin: 8px 0;
  font-size: 0.9rem;
}

.text-right i {
  margin-right: 8px;
}

.post-date {
  color: var(--main-color);
  opacity: 0.8;
}
</style>
