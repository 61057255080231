<script setup>
import { ref, onMounted } from 'vue';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';

const props = defineProps({
  articleId: {
    type: String,
    required: true
  },
  iconColor: {
    type: String,
    default: '#d06106'
  },
  textColor: {
    type: String,
    default: '#999999'
  }
});

const viewCount = ref(0);

function getViewText(count) {
  if (count === 0) return 'مشاهدة';
  if (count === 1) return 'مشاهدة';
  if (count === 2) return 'مشاهدتان';
  if (count >= 3 && count <= 10) return 'مشاهدات';
  return 'مشاهدة';
}

function setupViewCountListener() {
  const db = getDatabase();
  const viewsRef = dbRef(db, `articleViews/${props.articleId}/count`);
  onValue(viewsRef, (snapshot) => {
    viewCount.value = snapshot.val() || 0;
  });
}

onMounted(() => {
  setupViewCountListener();
});
</script>

<template>
  <div class="view-counter">
    <i class="fa-regular fa-eye" :style="{ color: iconColor }"></i>
    <span :style="{ color: textColor }">{{ viewCount }} {{ getViewText(viewCount) }}</span>
  </div>
</template>

<style scoped>
.view-counter {
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-counter i {
  margin: 0 3px;
}
</style> 