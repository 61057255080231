<template>
  <div class="content-wrapper">
    <div class="container">
      <div class="row mt-4 mb-4">
        <div
          v-for="ad in ads"
          :key="ad.id"
          class="mb-2 col-lg-6 image-hover"
        >
          <a :href="ad.adUrl" target="_blank" rel="noopener noreferrer">
            <img
              :src="ad.imgUrl"
              :alt="'إعلان'"
              class="rounded w-100 img-fluid h-100"
              loading="lazy"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getDatabase, ref as dbRef, onValue, off } from 'firebase/database';

const ads = ref([]);

onMounted(() => {
  const db = getDatabase();
  const adsRef = dbRef(db, "home/ads/");

  const unsubscribe = onValue(adsRef, (snapshot) => {
    ads.value = Object.entries(snapshot.val() || {}).map(([id, data]) => ({
      id,
      ...data
    }));
  });

  // Cleanup function
  return () => {
    off(adsRef);
    unsubscribe();
  };
});
</script>