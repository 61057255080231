<template>
  <!-- <div class="sk-ww-youtube-channel-videos" :data-embed-id="embedId"></div> -->
  <iframe src='https://widgets.sociablekit.com/youtube-playlists/iframe/25354313' 
  frameborder='0' width='100%' height='1000'></iframe>

</template>
<script setup>
import { onMounted } from 'vue';
import { setDocumentTitle } from '@/utils/utils';
onMounted(() => {
  setDocumentTitle('المكتبة المرئية');
});
</script>
