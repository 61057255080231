<template>
  <div class="singlePost">
    <section class="single-post" :style="{backgroundImage: `url(${props.imgUrl})`}">
    </section>
  </div>
</template>

<script setup>
const props = defineProps({
  imgUrl: {
    required: false,
    default: 'https://i.postimg.cc/prBhfrST/cykExNN.png'
  }
})
</script>

<style scoped>
.single-post {
  padding: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 60vh;
  margin-top: 82.84px !important;
  background-color: unset;
}

.landing {
  position: relative;
  padding: 10%;
  height: 100%;
}
</style>
