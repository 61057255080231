<template>
  <div v-if="category" class="category-card">
    <div class="card-image">
      <img :src="category.imgUrl" :alt="category.displayName" />
    </div>
    <div class="card-content">
      <h3 class="category-name">{{ category.displayName }}</h3>
      <p class="category-name-en">{{ category.displayNameEn }}</p>
      <p class="author-description">{{ category.description }}</p>
      <router-link :to="`/category/${category.category}`" class="view-category-btn">
        عرض المقالات
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  category: {
    type: Object,
    required: true
  }
});

const category = computed(() => props.category || {});
</script>

<style scoped>
.category-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.category-card:hover {
  transform: translateY(-5px);
}

.card-image {
  height: 150px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
}

.category-name {
  color: var(--scond-color);
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.category-name-en {
  color: var(--main-color);
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.category-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: var(--main-color);
}

.view-category-btn {
  display: block;
  text-align: center;
  background-color: var(--scond-color);
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: auto;
}

.view-category-btn:hover {
  background-color: #fff;
  color: var(--scond-color);
  border: 1px solid var(--scond-color);
}

.media-category {
  color: var(--scond-color);
  font-weight: bold;
}

.author-description {
  flex-grow: 1;
  height: 4.5em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}
</style>
