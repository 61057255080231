<template> 
  <div v-if="posts && posts.length > 0" class="swiper-container pb-4 pt-4">
    <h2 class="mb-2 sliderTitle">
      {{props.isChild ? posts[0]?.category.nameAr : posts[0]?.category?.parent?.nameAr }}
    </h2>
    <swiper
    :key="componentKey"
    :autoplay="{
      delay: 3000,
      autoplayDisableOnInteraction: false
          }"

      :modules="modules"
      :slides-per-view="swiperOptions.slidesPerView"
       :space-between="swiperOptions.spaceBetween" 

      navigation
      :loop="posts.length >3"
      :updateOnWindowResize="true"
    :renderExternalUpdate="true"
    :observer="true"
    :observeParents="true"
    :observeSlideChildren="true"
    :speed="1000"
    :height="600"
    :lazy="true"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
    
      <swiper-slide v-for="post in posts" :key="post.id">
        <div class="item">
          <div class="post-slide">
            <div class="post-img">
              <img :src="post.imgUrl" :alt="post.title"  loading="lazy" />
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <router-link :to="`/article/${post.id}`">{{ post.title }} </router-link >
              </h3>
              <p class="post-description ql-editor q-label" v-html="post.content"></p>
              <div class="all-btn">
                <router-link :to="`/article/${post.id}`" class="big-btn mid-btn mb-3">اقرأ المزيد</router-link>
              </div>
              <div class="post-meta">
                <div class="meta-top">
                  <span class="post-author">
                    <i class="fa-regular fa-circle-user"></i> {{post.author.displayName}}
                  </span>
                  <span class="post-date" style="white-space: nowrap;">
                    <i class="fa-regular fa-clock"></i>{{formatDate(post.addedAt)}}
                  </span>
                </div>
                <div class="meta-bottom">
                  <ViewCounter :articleId="post.id"
                   iconColor="#999999" 
                  textColor="#999999"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- Add more swiper-slide elements for additional slides -->
    </swiper>
  </div>
  </template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { defineAsyncComponent } from 'vue';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';
import ViewCounter from '@/components/ViewCounter.vue';
const Swiper = defineAsyncComponent(() => import('swiper/vue').then(m => m.Swiper));
const SwiperSlide = defineAsyncComponent(() => import('swiper/vue').then(m => m.SwiperSlide));

import { formatDate } from "@/utils/utils.js";

const props = defineProps({
  posts: {
    type: Array,
    required: true,
  },
  isChild:{
    type:Boolean,
    required: false,
    default:false
  }
});

const componentKey = ref(0);

const _posts=ref(null);


const swiperOptions = ref({
  slidesPerView: 3,
  spaceBetween: 10,
  pagination: {
    clickable: true,
  },
  scrollbar: {
    draggable: true,
  },
});

const modules = computed(() => [Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const router = useRouter();

const navigateToArticle = (articleId) => {
  router.push(`/article/${articleId}`);
};


const updateSlidesPerView = () => {
  if (window.innerWidth >= 992) {
    swiperOptions.value.slidesPerView = 3;
  } else if (window.innerWidth >= 768) {
    swiperOptions.value.slidesPerView = 2;
  } else {
    swiperOptions.value.slidesPerView = 1;
  }
};

const viewCounts = ref({});

function setupViewCountListener(articleId) {
  const db = getDatabase();
  const viewsRef = dbRef(db, `articleViews/${articleId}/count`);
  onValue(viewsRef, (snapshot) => {
    viewCounts.value[articleId] = snapshot.val() || 0;
  });
}

function getViewText(count) {
  if (count === 0) return 'مشاهدة';
  if (count === 1) return 'مشاهدة';
  if (count === 2) return 'مشاهدتان';
  if (count >= 3 && count <= 10) return 'مشاهدات';
  return 'مشاهدة';
}

watch(() => props.posts, (newPosts) => {
  if (newPosts && newPosts.length > 0) {
    componentKey.value += 1; // Force re-render of Swiper
    newPosts.forEach(post => {
      setupViewCountListener(post.id);
    });
  }
}, { immediate: true, deep: true });

onMounted(() => {
  updateSlidesPerView();
  window.addEventListener("resize", updateSlidesPerView);

});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSlidesPerView);
});

const onSwiper = (swiper) => {
};

const onSlideChange = () => {
  // console.log("slide change");
};
</script>
<style scoped>
.swiper-container {
  width: 100%;
  height: 100%;
}

.post-slide {
  background: #ffffff;
  margin: 20px 15px;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0px 14px 22px -9px rgba(141, 140, 140, 0.71);
}

.post-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 200px; /* Set a fixed height for the image container */
  min-height: 200px;
  max-height: 200px;
}

.post-slide .post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure the image covers the entire container */
  object-position: center; /* This will center the image within the container */
  transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
  transform: scale(1.1);
}

.post-slide .post-content {
  background: #ffffff;
  padding: 15px;
  border-radius: 15px;
}

.post-slide .post-title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

@media (max-width: 767px) { 
  .sliderTitle {
    font-weight: 700;
    font-size: 22px;
  }
}

.post-slide .post-title a {
  color: #333333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.post-slide .post-title a:hover {
  color: #d06106;
}

.post-slide .post-description {
  line-height: unset;
  color: #666666;
  margin-bottom: 15px;
}

.post-slide .all-btn {
  text-align: center;
}

.post-slide .big-btn {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  color: #ffffff;
  background-color: #d06106;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.post-slide .big-btn:hover {
  background-color: #b34e02;
}

.post-slide .post-meta {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #999999;
  font-size: 14px;
  width: 100%;
}

.meta-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.meta-bottom {
  justify-content: start;
  align-items: start;
}

.post-views {
  color: #999999;
  font-size: 14px;
}

.post-views i {
  margin-right: 5px;
  color: #d06106;
}

@media (min-width: 768px) {
  .post-slide {
    margin: 20px;
  }
}

@media (min-width: 992px) {
  .post-slide {
    width: 300px;
    margin: 20px;
  }
}

/* Add these new styles at the end of your <style> section */
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  color: var(--second-color);
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.swiper-button-next:hover),
:deep(.swiper-button-prev:hover) {
  color: var(--second-color-hover, var(--second-color));
  background-color: rgba(255, 255, 255, 1);
}

:deep(.swiper-button-next::after),
:deep(.swiper-button-prev::after) {
  font-size: 24px;
  font-weight: bold;
}

:deep(.swiper-pagination-bullet-active) {
  background-color: var(--second-color);
}

@media (max-width: 768px) {
  .meta-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.post-author {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}
</style>
