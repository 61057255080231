<script setup>
import {ref} from "vue"
import breadcrumb from '@/components/breadcrumb.vue'
import { useRoute } from 'vue-router';
import {ApiClient, ArticleControllerApi} from "@/api/index.js"
import {formatDate, setDocumentTitle} from "@/utils/utils.js"
import QuillView from "./QuillView.vue";
import { getDatabase, ref as dbRef, increment, update} from 'firebase/database';
import ViewCounter from '@/components/ViewCounter.vue';

const route =useRoute()
const article=ref(null);
const viewCount = ref(0);

const apiClient=new ApiClient();
const articleApi=new ArticleControllerApi(apiClient);

// Add these lines
const disableContextMenu = (e) => {
  e.preventDefault();
};

function incrementViewCount(articleId) {
  const db = getDatabase();
  const viewsRef = dbRef(db, `articleViews/${articleId}`);
  
  // Use an atomic transaction to safely increment the view count
  const updates = {};
  updates[`articleViews/${articleId}/count`] = increment(1);
  updates[`articleViews/${articleId}/lastViewed`] = new Date().toISOString();
  
  return update(dbRef(db), updates);
}

function checkAndIncrementViewCount(articleId) {
  const storageKey = `article_view_${articleId}`;
  const currentTime = new Date().getTime();
  const lastViewTime = localStorage.getItem(storageKey);

  // Check if article was viewed before and if 5 minutes have passed
  if (!lastViewTime || currentTime - parseInt(lastViewTime) > 5 * 60 * 1000) {
    // Store current timestamp
    localStorage.setItem(storageKey, currentTime.toString());
    
    // Increment view count in Firebase
    return incrementViewCount(articleId);
  }
  
  return Promise.resolve(); // Return resolved promise if view not counted
}

function loadArticle(){
  articleApi.getArticle(route.params.id,(error,data,response)=>{
    article.value=data;
    setDocumentTitle(article.value.title);
    
    // Setup listener and check before incrementing
    checkAndIncrementViewCount(route.params.id).catch(error => {
      console.error('Error updating view count:', error);
    });
  });
}
loadArticle();

document.addEventListener('contextmenu', disableContextMenu);
</script>
<template>
  <div class="singlePost" @contextmenu.prevent>
    <section class="singlePostVue1">
      <breadcrumb :imgUrl="article?.imgUrl"/>
      
      <nav class="article-path">
        <div class="container">
          <ol class="path-list">
            <li class="path-item">
              <router-link to="/">الرئيسية</router-link>
            </li>
            <li class="path-item" v-if="article?.category.parent">
              <router-link :to="`/category/${article.category.parent.id}`">
                {{ article.category.parent.nameAr }}
              </router-link>
            </li>
            <li class="path-item" v-if="article?.category.nameAr">
              <router-link :to="`/category/${article.category.id}`">
                {{ article.category.nameAr }}
              </router-link>
            </li>
            <li class="path-item active">
              {{ article?.title }}
            </li>
          </ol>
        </div>
      </nav>

      <div class="container mb-3">
        <div class="article-container">
          <article class="article-content">
            <!-- Title -->
            <h1 class="article-title">{{ article?.title }}</h1>
            
            <!-- Article Meta -->
            <div class="article-meta">
              <div class="meta-item">
                <i class="fa-regular fa-circle-user"></i>
                <span>{{ article?.author.displayName}}</span>
              </div>
              <div class="meta-item">
                <i class="fa-regular fa-clock"></i>
                <span>{{ formatDate(article?.addedAt)}}</span>
              </div>
              <div class="meta-item" v-if="article">
                <ViewCounter :articleId="article.id"
                 iconColor="var(--main-color)" textColor="#666"/>
              </div>
            </div>
            
            <!-- Article Body -->
            <div class="article-body">
              <QuillView :content="article?.content"/>
            </div>
          </article>
        </div>
      </div>

      <div class="container mb-3">
        <div class="author-card style-2">
          <div class="author-label">الكاتب</div>
          <div class="author-image">
            <img 
              :src="article?.author.imgUrl" 
              :alt="article?.author.displayName"
              @error="e => e.target.src = 'https://i.postimg.cc/prBhfrST/cykExNN.png'"
            >
          </div>
          <div class="author-info">
            <h3 class="author-name">{{ article?.author.displayName }}</h3>
            <p class="author-bio">{{ article?.author.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>
<script>
export default {
  name: "singlePost",
  props: {},
};
</script>
<style scoped>
.singlePostVue1 {
  padding: 0;
}
.singlePostVue1 .landing {
  /* background-image: url(""); */
  background-repeat: no-repeat;
  position: relative;
  background-position: center center;
  background-size: cover;
  padding: 10%;
  height: 40vh;
}

.img-singlePostVue1 {
  position: relative;
}
/* start  breadcrumb */
.breadcrumb {
  text-align: center;
  background-color: #ffffff6f;
}
/* iframe.ql-video {
   
} */

.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-item.active {
  color: var(--main-color);
}

.breadcrumb-item:hover {
  color: var(--scond-color);
}
/* end  breadcrumb */
.singlePostVue {
  background-color: rgba(241, 239, 239, 0.767);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-bottom: 15px;
}
.singlePostVue h5,.singlePostVue P {
  margin: 10px 0;
  font-size: 17px;
  padding: 10px 50px;
}
@media (max-width: 767px) { 
  .singlePostVue h5, .singlePostVue p {
    padding: 0;  /* Remove padding on mobile */
    text-align: center;
  }
}
/* end  singlePostVue */

/* Add these styles at the end */
.singlePost {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.article-container {
  margin: 0 auto;
  padding: 2rem 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-content {
  padding: 1rem;
}

.article-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--main-color);
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.3;
}

.article-meta {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
}

.author-name{
  color: var(--main-color);
}
.meta-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
}

.meta-item i {
  color: var(--main-color);
  font-size: 1.1rem;
}

.article-body {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
}

@media (max-width: 767px) {
  .article-container {
    padding: 1rem;
  }
  
  .article-title {
    font-size: 1.8rem;
  }
  
  .article-meta {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

.author-card {
  margin-top: 2rem;  /* Reduced from 3rem since it's now in its own container */
  background-color: #fff;  /* Added background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Added shadow to match article container */
  direction: rtl;
}

.author-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

/* Author Card Style */
.style-2 {
  padding: 2rem;
  border: 2px solid #eee;
  border-radius: 12px;
  display: flex;
  gap: 2rem;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
}

.author-label {
  position: absolute;
  top: -1rem;
  right: 2rem;
  background-color: var(--main-color);
  color: white;
  padding: 0.3rem 1.5rem;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.style-2:hover {
  border-color: var(--main-color);
  transform: translateY(-2px);
}

.style-2 .author-image img {
  border-radius: 50%;
  border: 3px solid var(--main-color);
}

@media (max-width: 767px) {
  .style-2 {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .author-image img {
    width: 100px;
    height: 100px;
  }

  .author-label {
    right: 50%;
    transform: translateX(50%);
  }
}

.meta-item i.fa-eye {
  font-size: 1.2rem;  /* Slightly larger than other icons */
}

@media (max-width: 767px) {
  .article-meta {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

.article-path {
  background: linear-gradient(to right, #ffffff, #faf8f8);
  padding: 1.2rem 0;
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px rgba(96, 11, 14, 0.08);
  border-bottom: 2px solid rgba(96, 11, 14, 0.1);
  position: relative;
  overflow: hidden;
}

.article-path::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(to right, #600b0e, transparent);
}

.path-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 0.8rem;
  direction: rtl;
  align-items: center;
}

.path-item {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  position: relative;
}

.path-item a {
  color: #600b0e;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.4rem 1rem;
  border-radius: 20px;
  background-color: rgba(96, 11, 14, 0.05);
  box-shadow: 0 2px 4px rgba(96, 11, 14, 0.1);
  position: relative;
  overflow: hidden;
  font-weight: 500;
}

.path-item a::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(96, 11, 14, 0.15);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease, height 0.6s ease;
}

.path-item a:hover::before {
  width: 300px;
  height: 300px;
}

.path-item a:hover {
  color: #fff;
  background-color: #600b0e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(96, 11, 14, 0.2);
}

.path-item + .path-item::before {
  content: "";
  width: 8px;
  height: 8px;
  border-right: 2px solid rgba(96, 11, 14, 0.4);
  border-bottom: 2px solid rgba(96, 11, 14, 0.4);
  transform: rotate(-45deg);
  margin: 0 0.5rem;
  transition: all 0.3s ease;
}

.path-item:hover + .path-item::before {
  border-color: #600b0e;
}

.path-item.active {
  color: #4a1618;
  padding: 0.4rem 1rem;
  border-radius: 20px;
  background: linear-gradient(145deg, #fff5f5, #fff);
  box-shadow: 3px 3px 6px rgba(96, 11, 14, 0.1),
             -3px -3px 6px #ffffff;
  font-weight: 500;
  transition: none;
  transform: none;
}

/* Remove hover animation for active item */
.path-item.active:hover {
  animation: none;
  transform: none;
}

@keyframes fadeIn {
  from { 
    opacity: 0; 
    transform: translateY(10px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
}

.path-item {
  animation: fadeIn 0.3s ease-out forwards;
}

.path-item:nth-child(1) { animation-delay: 0.1s; }
.path-item:nth-child(2) { animation-delay: 0.2s; }
.path-item:nth-child(3) { animation-delay: 0.3s; }
.path-item:nth-child(4) { animation:unset;}
</style>
