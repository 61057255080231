<script setup>
import {  ref, onMounted, onUnmounted } from 'vue';

const props = defineProps(['media']);

const currentlyPlaying = ref(null);
const selectedImage = ref(null);

const handleAudioPlay = (event) => {
  const audio = event.target;
  
  if (currentlyPlaying.value && currentlyPlaying.value !== audio) {
    currentlyPlaying.value.pause();
  }
  
  currentlyPlaying.value = audio;
};

const openLightbox = (image) => {
  if (props.media.mediaType === 0) {
    selectedImage.value = image;
  }
};

const closeLightbox = () => {
  selectedImage.value = null;
};

const preventImageDownload = (event) => {
  event.preventDefault();
};

const handleKeyDown = (event) => {
  if (selectedImage.value) {
    if (event.key === 'Escape') {
      closeLightbox();
    } else if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      navigateLightbox(event.key === 'ArrowRight' ? 1 : -1);
    }
  }
};

const navigateLightbox = (direction) => {
  if (!selectedImage.value) return;
  
  const currentIndex = props.media.contentEntities.findIndex(img => img.id === selectedImage.value.id);
  const newIndex = (currentIndex + direction + props.media.contentEntities.length) % props.media.contentEntities.length;
  selectedImage.value = props.media.contentEntities[newIndex];
};


const handleMediaError = (event) => {
  event.target.src = 'https://i.imgur.com/cykExNN.png'; // Replace with your fallback image path
};

onMounted(() => {
  document.addEventListener('contextmenu', preventImageDownload);
  document.addEventListener('dragstart', preventImageDownload);
  document.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
  document.removeEventListener('contextmenu', preventImageDownload);
  document.removeEventListener('dragstart', preventImageDownload);
  document.removeEventListener('keydown', handleKeyDown);
});
</script>

<template>
  <div class="childSeries" style="background-color: var(--section-background)">
    <div class="container">
      <div class="headerSeries row">
        <div class="imagecontainer col-lg-6">
          <div class="post-img">
            <img :src="media.imgUrl" :alt="media.title" @contextmenu.prevent @dragstart.prevent />
          </div>
        </div>
        <div class="description col-lg-6">
          <h5>{{ media.title }}</h5>
          <div class="line"></div>
          <p>{{ media.description }}</p>
          <div class="line"></div>
          <div class="row info">
            <div class="col-6">
              <p><span>تحرير: </span> {{ media.author.displayName }}</p>
            </div>
            <div class="col-6">
              <p><span>لغة العمل :</span> {{ media.lang === 1 ? 'العربية' : 'English' }}</p>
            </div>
            <div class="col-6">
              <p><span>القسم:</span> {{ media.category.nameAr }}</p>
            </div>
          </div>
        </div>
      </div>
      <h5>{{ media.mediaType === 1 ? 'حلقات' : 'صور' }} {{ media.title }}</h5>
      <div class="line"></div>
      <div class="row">
        <div class="col-sm-6 col-lg-4" v-for="_media in media.contentEntities" :key="_media.id">
          <div class="item" @click="openLightbox(_media)">
            <div class="post-slide">
              <div class="post-img">
                <img v-if="media.mediaType === 0" 
                     :src="_media.mediaUrl" 
                     :alt="_media.title" 
                     @contextmenu.prevent 
                     @dragstart.prevent 
                     loading="lazy"
                     @error="handleMediaError"/>
                <audio 
                  v-else-if="media.mediaType === 1" 
                  controls 
                  @play="handleAudioPlay"
                  controlsList="nodownload"
                  @error="handleMediaError"
                >
                  <source :src="_media.mediaUrl" type="audio/mpeg">
                  يبدو أن متصفحك لا يدعم الصوت
                </audio>
              </div>
              <div class="post-content">
                <h4 class="post-title">
                  {{ _media.title }}
                </h4>
                <!-- <h3 class="post-title">
                  <a href="#">{{ media.mediaType === 1 ? 'الحلقة' : 'الصورة' }} : {{ _media.id }}</a>
                </h3> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Updated Lightbox -->
      <div v-if="selectedImage && media.mediaType === 0" class="lightbox" @click="closeLightbox">
        <div class="lightbox-content" @click.stop>
          <img :src="selectedImage.mediaUrl" :alt="selectedImage.title" @contextmenu.prevent @dragstart.prevent loading="lazy">
          <div class="lightbox-info">
            <h3>{{ selectedImage.title }}</h3>
            <p>{{ selectedImage.description }}</p>
          </div>
          <div class="lightbox-nav">
            <button @click="navigateLightbox(-1)">&lt; السابق</button>
            <button @click="navigateLightbox(1)">التالي &gt;</button>
          </div>
          <button class="close-button" @click="closeLightbox">&times;</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.headerSeries{
  position: relative;
  overflow: hidden;
 
}
 h5{
  text-align: right;
  margin: 30px 0 15px;
  color: var(--main-color);
  font-weight: 700;
  width: 100%;
       

}
.line::after {
        content: "";
        position: absolute;
        width: 10%;
        height: 3px;
        margin: 0;
        display: block;
        background-color: var(--scond-color);
    }
.headerSeries img{
  width: 100%;
  margin: 30px 10px;
  border-bottom: 4px solid var(--main-color);
}
.headerSeries p{
  margin: 30px 0 20px;
  color: var(--main-color);
  font-weight: 500;

}
.info p{
  margin: 10px 0 0;
  color: var(--main-color);
  font-weight: 500;

}
.info span{
  margin: 10px 0 0 0;
  color: var(--main-color);
  font-weight: 600;

}
.post-slide {
  background: var(--section-background);
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #8d8c8cb5;
}

.post-slide .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;

}

.post-slide .post-img img {
  width: 100%;
  height: auto;
  transform: scale(1, 1);
  transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
  transform: scale(1.1, 1.1);
}

.post-slide .post-content {
  background: var(--section-background);
  padding: 1px 20px 15px;
  border-radius: 15px;
}

.post-slide .post-title a {
  font-size: 16px;
  font-weight: bold;
  color: var(--scond-color);
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  text-align: center;
  margin: 0;
}

.post-slide .post-title a:hover {
  text-decoration: none;
  color: var(--scond-color);
}

.post-slide .post-img audio {
  width: 100%;
  margin-top: 10px;
}

/* Add these new styles for the lightbox */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  max-width: 90%;
  max-height: 90vh;
  background-color: var(--section-background);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
}

.lightbox-content img {
  max-width: 100%;
  max-height: calc(90vh - 150px);
  object-fit: contain;
}

.lightbox-info {
  padding: 20px;
  text-align: right;
}

.lightbox-content h3 {
  margin-top: 10px;
  color: var(--main-color);
  font-weight: 700;
}

.lightbox-content p {
  margin-top: 10px;
  color: var(--main-color);
  font-weight: 500;
}

.lightbox-nav {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
}

.lightbox-nav button {
  padding: 10px 20px;
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.lightbox-nav button:hover {
  background-color: var(--scond-color);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--main-color);
  font-size: 30px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: var(--scond-color);
}

/* Only apply hover effect to images */
.item:has(img) {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.item:has(img):hover {
  transform: scale(1.05);
}

/* Add these new styles to disable user-select and pointer-events */
img {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.image-container {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--main-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
</style>
