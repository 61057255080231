<template>
  <div class="goUpVue">
    <button
      @click="scrollToTop"
      class="goup"
      aria-label="Scroll to top"
      :class="{ 'goup--visible': isVisible }"
    >
      <i class="fa fa-arrow-up"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "GoUpVue",
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.toggleVisibility);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.toggleVisibility);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    toggleVisibility() {
      this.isVisible = window.scrollY > 300;
    },
  },
};
</script>

<style scoped>
.goup {
  position: fixed;
  bottom: 10px;
  z-index: 999;
  right: 15px;
  border-radius: 50%;
  display: block;
  background-color: var(--main-color);
  color: var(--scond-color);
  width: 50px;
  height: 50px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, background-color 0.3s, color 0.3s;
}
.goup--visible {
  opacity: 0.5;
  visibility: visible;
}
.goup:hover {
  background-color: var(--scond-color);
  color: var(--main-color);
  transform: translateY(-5px);
  opacity: 1;
}
</style>
