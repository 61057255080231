<template>
  <div class="external-authors-categories">
    <breadcrumb :imgUrl="headerImage" :title="pageTitle" />
    <div class="container">
      <h1 class="page-title">{{ pageTitle }}</h1>
      <div class="category-grid">
        <AuthorCategoryCard
          v-for="category in filteredCategories"
          :key="category.id"
          :category="category"
        />
      </div>
      <div v-if="categories.length === 0" class="no-results">
        {{ msg }}
      </div>
    </div>
    <pagination
      v-if="totalPages > 1"
      :totalPages="totalPages"
      :currentPage="currentPage"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import breadcrumb from '@/components/breadcrumb.vue';
import Pagination from '@/components/Pagination.vue';
import AuthorCategoryCard from '@/components/AuthorCategoryCard.vue';
import { ApiClient, CategoryControllerApi } from "@/api/index.js";
import { setDocumentTitle } from '@/utils/utils';
const apiClient = new ApiClient();
const categoryControllerApi = new CategoryControllerApi(apiClient);

const pageTitle = ref("الصفحات الشخصية");
const headerImage = ref("https://i.imgur.com/cykExNN.png");
const categories = ref([]);
const filteredCategories = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = ref(12);
const msg = ref("جاري التحميل...");

const router = useRouter();
const route = useRoute();

const fetchCategories = async () => {
  try {
    setDocumentTitle('الصفحات الشخصية');
    categoryControllerApi.getChildCategories("personal_pages",(error,data,response)=>{
      categories.value = data; 
      filteredCategories.value = categories.value;
      totalPages.value = Math.ceil(data.length / pageSize.value);
      if(data.length === 0){
        msg.value = "لا يوجد صفحات بعد";
      }
    });
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

const handlePageChange = (page) => {
  currentPage.value = page;
  // Update the URL with the new page number
  router.push({ query: { ...route.query, page: page } });
  updateFilteredCategories();
};

const updateFilteredCategories = () => {
  const startIndex = (currentPage.value - 1) * pageSize.value;
  const endIndex = startIndex + pageSize.value;
  filteredCategories.value = categories.value.slice(startIndex, endIndex);
};

onMounted(() => {
  // Check if there's a page number in the URL and set it
  const pageFromQuery = Number(route.query.page);
  if (pageFromQuery && !isNaN(pageFromQuery)) {
    currentPage.value = pageFromQuery;
  }
  fetchCategories();
});

watch(categories, () => {
  totalPages.value = Math.ceil(categories.value.length / pageSize.value);
  updateFilteredCategories();
});
</script>

<style scoped>
.external-authors-categories {
  background-color: var(--section-background);
}

.container {
  padding: 2rem 0;
}

.page-title {
  color: var(--scond-color);
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.no-results {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  color: var(--scond-color);
}

</style>
