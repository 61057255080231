<template>
  <div class="social-media-container">
    <a href="https://x.com/yafe3pedia" target="_blank" class="social-btn">
      <i class="fab fa-x-twitter"></i>
    </a>
    <a href="https://www.facebook.com/yafeapedia/" target="_blank" class="social-btn">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a href="https://www.youtube.com/channel/UCl5Zkl4hEr8Rt33uQ16HPAw" target="_blank" class="social-btn">
      <i class="fab fa-youtube"></i>
    </a>
    <a href="https://www.instagram.com/yafeapedia/" target="_blank" class="social-btn">
      <i class="fab fa-instagram"></i>
    </a>
  </div>
</template>

<style scoped>
.social-media-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust this value as needed */
  margin: 0 auto;
  padding: 2rem 1rem;
}

.social-btn {
  font-size: 4rem; /* Increased from 3rem */
  width: 5rem; /* Increased from 4rem */
  height: 5rem; /* Increased from 4rem */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F15827;
  transition: color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.social-btn:hover {
  color: #d14720;
  transform: scale(1.1);
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .social-btn {
    font-size: 3rem; /* Increased from 2rem */
    width: 4rem; /* Increased from 3rem */
    height: 4rem; /* Increased from 3rem */
  }
}

/* Additional media query for even smaller screens */
@media (max-width: 480px) {
  .social-btn {
    font-size: 4rem;
    width: 4.5rem;
    height: 4.5rem;
  }
}
</style>
