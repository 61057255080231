<template>
  <div class="categories-container">
    <a v-for="category in props.categories"
       :key="category.id"
       :href="`/category/${category.id}`"
       class="category-button"
       :title="category.nameAr">
      <slot :name="`icon-${category.id}`">
        <!-- Optional default icon -->
      </slot>
      <span class="category-text">{{ category.nameAr }}</span>
    </a>
  </div>
</template>

<script setup>
const props = defineProps(['categories']);
</script>

<style scoped>
.categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
  justify-content: center;
  margin: 3rem auto 0.5rem;
  max-width: 1200px;
  padding: 0 1.5rem;
  perspective: 1000px;
}

.category-button {
  padding: 0.9rem 1.4rem;
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;
  background-color: rgba(241, 88, 39, 0.9);
  border-radius: 999px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 15px rgba(241, 88, 39, 0.2);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  transform: scale(1) rotateX(0);
  will-change: transform;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.category-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}

.category-button:hover {
  transform: translateY(-3px) scale(1.05) rotateX(5deg);
  box-shadow: 0 8px 25px rgba(96, 11, 14, 0.25);
  background-color: rgba(96, 11, 14, 0.95);
  animation: pulse 1.5s infinite;
}

.category-button:hover::before {
  transform: translateX(100%);
}

.category-button:active {
  transform: translateY(-1px) scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.category-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(96, 11, 14, 0.3),
              0 4px 15px rgba(0, 0, 0, 0.1);
}

.category-button::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  border-radius: inherit;
  pointer-events: none;
}

.category-text {
  position: relative;
  z-index: 1;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.01em;
}

@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(241, 88, 39, 0.2);
  }
  50% {
    box-shadow: 0 4px 25px rgba(96, 11, 14, 0.3);
  }
  100% {
    box-shadow: 0 4px 15px rgba(241, 88, 39, 0.2);
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.category-button.loading {
  background: linear-gradient(
    90deg,
    rgba(241, 88, 39, 0.8) 25%,
    rgba(241, 88, 39, 0.95) 50%,
    rgba(241, 88, 39, 0.8) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@media (max-width: 768px) {
  .category-button {
    font-size: 1.1rem;
    padding: 0.75rem 1.2rem;
  }
  
  .categories-container {
    gap: 1rem;
  }
}
</style>