<template>
  <div class="ql-editor"
       style="color: #d06106;"
       v-html="props.content"
       @selectstart.prevent
       ref="editorRef" />
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  content: {
    required: true,
    default: ''
  }
});

const editorRef = ref(null);

onMounted(() => {
  if (editorRef.value) {
    editorRef.value.style.userSelect = 'none';
    editorRef.value.style.webkitUserSelect = 'none';
    editorRef.value.style.msUserSelect = 'none';
    
    editorRef.value.addEventListener('copy', (e) => {
      e.preventDefault();
    });
  }
});
</script>

<style scoped>
.ql-editor {
  cursor: default;
}
</style>
