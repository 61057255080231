<template>
  <div class="names-container">
    <div v-for="person in people" :key="person.name" class="person-card">
      <h2 class="person-name">{{ person.name }}</h2>
      <p class="person-title">{{ person.title }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const people = ref([
  { name: 'محمد سالم بن علي جابر', title: 'المشرف العام' },
  { name: 'د. نادر سعد العمري', title: 'المحرر العلمي' },
  { name: 'قاسم عمر المشتهر', title: 'المدير العام' },
]);
</script>

<style scoped>
.names-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: var(--section-background);
  box-shadow: 0 3px 10px rgba(117, 117, 117, 0.599);
}

.person-card {
  flex: 1 1 300px;
  text-align: center;
  padding: 2rem 1rem;
}

.person-name {
  color: var(--main-color);
  font-family: 'Cairo', 'Roboto', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.person-title {
  color: var(--main-color);
  font-family: 'Cairo', 'Roboto', sans-serif;
  font-size: 1.25rem;
}
</style>
