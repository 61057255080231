<template>
    <div class="pagination">
      <button 
        class="page-btn" 
        :disabled="currentPage === 1" 
        @click="changePage(currentPage - 1)">
        السابق
      </button>
      <button 

        v-for="page in totalPages" 
        :key="page" 
        :class="['page-btn', { active: currentPage === page }]" 
        @click="changePage(page)">
        {{ page }}
      </button>
      <button 
        class="page-btn" 
        :disabled="currentPage === totalPages" 
        @click="changePage(currentPage + 1)">
        التالي
      </button>
    </div>
  </template>
  
  <script setup>
  
  const props = defineProps({
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  });
  
  const emit = defineEmits(['page-changed']);
  
  const changePage = (page) => {
    emit('page-changed', page);
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .page-btn {
    background-color: var(--main-color);
    color: var(--text-color);
    border: 1px solid var(--scond-color);
    padding: 10px 15px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .page-btn:disabled {
    background-color: var(--section-background);
    color: var(--main-color);
    cursor: not-allowed;
  }
  
  .page-btn.active {
    background-color: var(--scond-color);
    color: var(--main-color);
  }
  
  .page-btn:hover:not(:disabled) {
    background-color: var(--scond-color);
    color: var(--main-color);
  }
  </style>