<template>
  <div class="Media">
    <!-- <Breadcrumb /> -->
    <div class="parentSeries margin-top-82">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-4">
            <AlbumView :media="albums" v-if="albums" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AlbumView from '@/components/AlbumView.vue'
import { ref, onMounted } from 'vue'
import { ApiClient, AlbumDTO, MediaControllerApi } from '@/api/index.js'
import { useRoute } from 'vue-router';
import { setDocumentTitle } from '@/utils/utils';
const albums = ref(null)
const apiClient = new ApiClient();
const mediaApi = new MediaControllerApi(apiClient);

const route = useRoute()

function getMedia() {
  mediaApi.getMediaById(route.params.id, (error, data, response) => {
    if (error) {
      console.log(error);
    } else {
      parseAlbums(data);
    }
  });  
};

function parseAlbums(data) {
  albums.value = AlbumDTO.constructFromObject(data);
  setDocumentTitle(albums.value.title);
}

onMounted(() => {
  getMedia();
})
</script>
<style scoped>
.margin-top-82 {
  margin-top: 82.84px !important;
}
</style>
