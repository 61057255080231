<template>
  <div class="search-view">
    <div class="container py-4">
      <h1 class="search-title mb-3">البحث</h1>
      
      <div class="search-form mb-4">
        <div class="input-group">
          <input
            v-model="searchQuery"
            type="text"
            class="form-control search-input"
            placeholder="ابحث عن مقالات، صور، فيديوهات..."
          />
          <button @click="handleSearch" class="btn btn-primary search-button">بحث</button>
        </div>
        <p v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</p>
      </div>

      <div v-if="isLoading" class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">جاري البحث...</span>
        </div>
      </div>

      <div v-else-if="searchResults.length === 0 && searchQuery" class="text-center">
        <p>لا توجد نتائج للبحث</p>
      </div>

      <div v-else class="row g-3">
        <div v-for="result in searchResults" :key="result.id" class="col-sm-6 col-lg-4">
          <cardVue v-if="result.type === 'article' || result.type === 'particle'" :article="result" />
          <div v-else-if="result.type === 'media'" class="card h-100">
            <img :src="result.imgUrl" class="card-img-top" :alt="result.title">
            <div class="card-body">
              <h5 class="card-title">{{ result.title }}</h5>
              <p class="card-text">{{ result.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <Pagination 
        v-if="totalPages > 1"
        :totalPages="totalPages" 
        :currentPage="currentPage" 
        @page-changed="handlePageChange"
        class="mt-4" 
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import breadcrumb from '@/components/breadcrumb.vue';
import cardVue from '@/components/cardVue.vue';
import Pagination from '@/components/Pagination.vue';
import { ApiClient, SearchControllerApi } from "@/api/index.js";
import { setDocumentTitle } from '@/utils/utils';
const router = useRouter();
const route = useRoute();

const apiClient = new ApiClient();
const searchApi = new SearchControllerApi(apiClient);

const headerImage = ref("https://i.imgur.com/cykExNN.png");
const searchQuery = ref('');
const searchResults = ref([]);
const isLoading = ref(false);
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = ref(9);
const errorMessage = ref('');

const sanitizeInput = (input) => {
  // Basic XSS and SQL injection filter
  return input.replace(/[<>'";&]/g, '');
};

const handleSearch = () => {
  const sanitizedQuery = sanitizeInput(searchQuery.value);
  if (sanitizedQuery.length >= 3) {
    errorMessage.value = '';
    fetchSearchResults(sanitizedQuery);
  } else if (sanitizedQuery.length > 0) {
    errorMessage.value = 'يجب أن يحتوي البحث على 3 أحرف على الأقل';
    searchResults.value = [];
    totalPages.value = 1;
  } else {
    errorMessage.value = '';
    searchResults.value = [];
    totalPages.value = 1;
  }
};

const fetchSearchResults = (query) => {
  isLoading.value = true;
  const lang = 1; // Assuming 1 is the language code for Arabic

  Promise.all([
    new Promise((resolve) => {
      searchApi.searchArticles(currentPage.value - 1, pageSize.value, query, lang, (error, data) => {
        if (error) {
          console.error("Error fetching articles:", error);
          resolve([]);
        } else {
          resolve(data.content.map(item => ({ ...item, type: 'article' })));
        }
      });
    }),
    new Promise((resolve) => {
      searchApi.searchPArticles(currentPage.value - 1, pageSize.value, query, lang, 0, (error, data) => {
        if (error) {
          console.error("Error fetching particles:", error);
          resolve([]);
        } else {
          resolve(data.content.map(item => ({ ...item, type: 'particle' })));
        }
      });
    }),
    new Promise((resolve) => {
      searchApi.searchMedia(currentPage.value - 1, pageSize.value, query, lang, (error, data) => {
        if (error) {
          console.error("Error fetching media:", error);
          resolve([]);
        } else {
          resolve(data.content.map(item => ({ ...item, type: 'media' })));
        }
      });
    })
  ]).then(([articles, particles, media]) => {
    searchResults.value = [...articles, ...particles, ...media];
    totalPages.value = Math.max(
      articles.totalPages || 1,
      particles.totalPages || 1,
      media.totalPages || 1
    );
    isLoading.value = false;
  });
};

const handlePageChange = (page) => {
  currentPage.value = page;
  fetchSearchResults();
};

watch(searchQuery, () => {
  currentPage.value = 1;
});

onMounted(() => {
  const queryParam = route.query.q;
  setDocumentTitle('البحث');
  if (!queryParam) {
    router.push('/');
  } else {
    searchQuery.value = sanitizeInput(queryParam);
    handleSearch();
  }
});

// Watch for changes in the route
watch(() => route.query.q, (newQuery) => {
  if (!newQuery) {
    router.push('/');
  } else {
    searchQuery.value = sanitizeInput(newQuery);
    handleSearch();
  }
});
</script>

<style scoped>
.search-view {
  background-color: var(--section-background);
  min-height: 100vh;
}

.search-title {
  color: var(--scond-color);
  font-weight: 700;
  font-size: 1.75rem;
}

.search-form {
  max-width: 500px;
  margin: 0 auto;
}

.search-input {
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 1rem;
  border: 2px solid var(--main-color);
}

.search-input:focus {
  border-color: var(--scond-color);
  box-shadow: 0 0 0 0.2rem rgba(241, 88, 39, 0.25);
}

.search-button {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
  transition: all 0.3s ease;
}

.search-button:hover,
.search-button:focus {
  background-color: var(--main-color, #005a8e);
  border-color: var(--main-color, #005a8e);
  color: #fff;
  opacity: 0.9;
}

.search-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
