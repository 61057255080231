import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import MediaView from '../views/MediaView.vue';
// import seriesView from '../views/seriesView.vue'
// import videoView from '../views/videoView.vue'
import categoriesView from '../views/categoriesView.vue';
import articleView from '../views/articleView.vue';
import channelView from '../views/channelView.vue';
import PersonalPages from '../views/PersonalPages.vue';
import searchView from '../views/SearchView.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/category/:id',
    name: 'category',
    component: categoriesView
  }, 
  {
    path: '/article/:id',
    name: 'article',
    component: articleView
  },
  {
    path: '/media/:id/:t',
    name: 'Media',
    component: MediaView
  },
  {
    path: '/videos',
    name: 'videos',
    component : channelView
  },
  // {
  //   path: '/serie/:parentId',
  //   name: 'series',
  //   component: seriesView,
  //   videos: [
  //     {
  //       path: 'video/:videosId',
  //       name: 'video',
  //       component:videoView
        
  //     },
  //   ]
  // },
  {
    path: '/personal_pages',
    name: 'personal_pages',
    component : PersonalPages
  },  
  {
    path: '/search',
    name: 'search',
    component : searchView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/yafiea_magazine',
    name: 'yafiea_magazine',
    beforeEnter: () => {
      window.open('https://www.facebook.com/profile.php?id=100080111221586&mibextid=ZbWKwL', '_blank');
      return false; // Prevents navigation in the current window
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
